import styled from 'styled-components';
import { direction } from "../../utils/consts.util";

const styledType = (type) => {
  switch (type) {
    case direction.PAYOUT:
      return`
        color: #FF5B5B;
        &:before {
          content: "\\e908";
        }
      `;
    case direction.PAYMENT:
      return`
        color: #1FC173;
        &:before {
          content: "\\e909";
        }
      `;
    default:
      return `
        color: #388AD2;
        &:before {
          content: "\\e958";
        }
      `;
  }
}

export const StyledDirectionType = styled.div`
  padding-left: 20px;
  text-transform: lowercase;
  display: inline-flex;
  align-items: center;
  position: relative;
  &:before {
    width: 15px;
    font-family: 'theme-icon', serif;
    text-align: center;
    position: absolute;
    top: 4px;
    left: 0;
  }
  ${({type}) => styledType(type)}
`;