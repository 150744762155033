import i18n from "i18next";
import React from "react";
import { NavLink } from "react-router-dom";
import { defaultLanguage } from "../../utils/consts.util";
import { removeTrailingSlash } from "../../utils/customFunk";

const CustomLink = ({ children, to, className, activeClassName, onClick, target, title, exact, dataTestId = null }) => {
  const language = i18n.language === defaultLanguage ? "" : "/" + i18n.language;

  const WpRoutes = [
    "cities",
    "about-us",
    "partners",
    "network-confirmations",
    "all-exchange-pairs",
    "bitcoin-price",
    "cryptocurrency-exchange",
    "buy-cryptocurrency",
    "bitcoin-wallet",
    "exchange-usdt",
    "crypto-dictionary",
    "rates",
    "news",
    "bounty-programma",
    "tariff",
    "useterms",
    "privacy",
    "kyc-and-aml",
    "exchange-regulations",
    "cookies-policy",
    "card-verification-manual"
  ];
  const WPRoutesWithSubRoutes = [
    "city"
  ];
  const allRoutes = [...WpRoutes, ...WPRoutesWithSubRoutes];
  const regexString = allRoutes.map(route => {
    if (WPRoutesWithSubRoutes.includes(route)) {
      return `\\/${route}\\/`;
    } else {
      return `\\/${route}`;
    }
  }).join("|");
  const WPLink = new RegExp(regexString).test(to);
  const WPAdmin = /\/wp-admin/.test(to);

  return (
    !WPLink && !WPAdmin ?
      <NavLink
        to={`${removeTrailingSlash(`${language}${to}`)}`}
        className={className}
        activeClassName={activeClassName}
        onClick={onClick}
        target={target}
        title={title}
        exact={exact}
        data-testid={dataTestId}
      >
        {children}
      </NavLink>
      :
      <a
        href={WPAdmin ? to : `${language}${to}`}
        className={className}
        onClick={onClick}
        target={target}
        title={title}
        data-testid={dataTestId}
      >
        {children}
      </a>
  );
};

export default CustomLink;