import axios from "axios";
import { responseStatus } from "./consts.util";

let timeOffset = null;

export const getTimeOffset = async () => {
  try {
    const response = await axios.get("/api/server-time");

    if (response.status === responseStatus.HTTP_OK) {
      const serverTime = response.data;
      const clientTime = Date.now();

      timeOffset = serverTime - clientTime;
    }
  } catch (error) {
    timeOffset = null;
  }
};

export const getSynchronizedTime = () => {
  if (timeOffset === null) {
    return new Date();
  } else {
    return new Date(Date.now() + timeOffset);
  }
};

export const Receiver_BIRTH_DAY = () => {
  const prevYear = (getSynchronizedTime().getFullYear() - 1).toString();

  return `^(0[1-9]|[1-2]\\d|3[0-1])\\.(0[1-9]|1[0-2])\\.(19\\d{2}|20[0-1][0-9]|202[0-${prevYear.slice(-1)}])$`;
}

export const getCurrentTimeOffset = () => {
  return timeOffset;
}