import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { TimestampToDate } from "../../utils/timestampToDate.utils";
import { requisitionStatus } from "../../utils/requsition.status";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import Can from "../can/can.component";
import RequisitionCity from "../requisition-list/requisition-city.component";

import { StyledRequisitionStatus } from "../requisition-list/styled-requisition";
import { StyledDirectionType } from "../styles/styled-direction-type";
import { StyledCol, StyledRow } from "../styles/styled-table";

import { requisition } from "../../rbac-consts";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import { direction } from "../../utils/consts.util";
import CustomLink from "../customLink/CustomLink";

const OldRequisitionItem = ({ oldRequisition }) => {
  const clientApollo = useApolloClient();

  const { userRole } = clientApollo.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  const { payment, payout } = oldRequisition.pair;
  const { id: clientId, firstname, lastname, email } = oldRequisition.client;

  let payoutAmountNew = payout.paymentSystem.tag === "CASH" && userRole === "client"
    ? oldRequisition.payoutAmount.toString().split(".")[0]
    : oldRequisition.payoutAmount;

  return (
    <StyledRow
      style={{ cursor: "inherit" }}
      scroll="auto"
      col={userRole !== "client" ? "9" : "6"}
      className="requisition-table__row"
      title={`Перейти в детали заявки ${oldRequisition.id.split("-")[0]}`}
    >
      <StyledCol data-title="Номер" className="requisition-table__number">
        <CopyToClipboard
          text={oldRequisition.id.split("-")[0].toUpperCase()}
          onCopy={() => {
            closableNotificationWithClick("Скопировано", "success");
          }}
        >
          <p title="Скопировать">
            {oldRequisition.id.split("-")[0].toUpperCase()}
            <span className="icon-copy" />
          </p>
        </CopyToClipboard>
      </StyledCol>
      <StyledCol data-title="Дата создания" className="requisition-table__date">
        {TimestampToDate(oldRequisition.createdAt)}
      </StyledCol>
      <Can
        role={userRole}
        perform={requisition.DATE_INFO}
        yes={() => (
          <StyledCol
            data-title="Дата выполнения"
            className="requisition-table__end-date"
          >
            {oldRequisition.endDate ? TimestampToDate(oldRequisition.endDate) : <span>00.00.0000 --:--</span>}
          </StyledCol>
        )}
      />
      <Can
        role={userRole}
        perform={requisition.CLIENT_DETAILS}
        yes={() => (
          <StyledCol
            data-title="Клиент"
            className="requisition-table__client user"
          >
            <div className="user__name">
              <CustomLink
                to={`/panel/clients/${clientId}`}
                title={`Просмотреть профиль клиента ${firstname} ${lastname}`}
              >
                {firstname} {lastname}
              </CustomLink>
              <CopyToClipboard
                text={`${firstname} ${lastname}`}
                onCopy={() => {
                  closableNotificationWithClick("Скопировано", "success");
                }}
              >
                <span className="icon-copy" title="Скопировать имя и фамилию" />
              </CopyToClipboard>
            </div>
            <div className="user__email">
              {email}
              <CopyToClipboard
                text={email}
                onCopy={() => {
                  closableNotificationWithClick("Скопировано", "success");
                }}
              >
                <span className="icon-copy" title="Скопировать e-mail" />
              </CopyToClipboard>
            </div>
          </StyledCol>
        )}
      />
      <StyledCol
        data-title="Платежная система"
        className="requisition-table__payment-system payment-system"
      >
        <div className="payment-system__name">{payment.paymentSystem.name}</div>
        <div className="payment-system__city">
          {(oldRequisition.exchangePoint === "bank" && payment.paymentSystem.tag === "CRYPTO" && payout.paymentSystem.tag === "CRYPTO") ? "Интернет платеж" : oldRequisition.exchangePoint === "bank" ? "Безналичный расчет" :
            <RequisitionCity exchangePoint={oldRequisition.exchangePoint} />}
        </div>
        <StyledDirectionType
          className="payment-system__type"
          type={payment.currency.tag === "CRYPTO" && payout.currency.tag === "CRYPTO" ? direction.EXCHANGE : payment.currency.tag === "CRYPTO" ? direction.PAYOUT : direction.PAYMENT}
        >
          {payment.currency.tag === "CRYPTO" && payout.currency.tag === "CRYPTO" ? "обмен" : payment.currency.tag === "CRYPTO" ? "продажа" : "покупка"}
        </StyledDirectionType>
      </StyledCol>
      <StyledCol data-title="Сумма платежа" className="requisition-table__in ">
        <div className="amount">
          <div className="amount__num">{payoutAmountNew}</div>
          <div className="amount__currency">{payment.currency.asset}</div>
        </div>
      </StyledCol>
      <StyledCol
        data-title="Сумма к получению"
        className="requisition-table__out"
      >
        <div className="amount">
          <div className="amount__num">{payoutAmountNew}</div>
          <div className="amount__currency">{payout.currency.asset}</div>
        </div>
      </StyledCol>
      <Can
        role={userRole}
        perform={requisition.USER_INFO}
        yes={() => (
          <StyledCol
            data-title="Менеджер"
            className="requisition-table__manager user"
          >
            {(oldRequisition.exchangePoint === "bank" && payment.paymentSystem.tag === "CRYPTO" && payout.paymentSystem.tag === "CRYPTO") ?
              <div className="user__empty">Интернет платеж</div> : oldRequisition.exchangePoint === "bank" ?
                <div className="user__empty">Безналичный расчет</div> : oldRequisition.manager ? (
                  <React.Fragment>
                    <div className="user__name">
                      {oldRequisition.manager.firstname} {oldRequisition.manager.lastname}
                    </div>
                    <div className="user__email">{oldRequisition.manager.email}</div>
                  </React.Fragment>
                ) : (
                  <div className="user__empty">Не назначен</div>
                )}
          </StyledCol>
        )}
      />
      <StyledCol data-title="Статус" className="requisition-table__status">
        <StyledRequisitionStatus status={oldRequisition.status}>
          {requisitionStatus(oldRequisition.status)}
        </StyledRequisitionStatus>
      </StyledCol>
    </StyledRow>
  );
};

export default OldRequisitionItem;
