import React from 'react';

import { StyledAlertWrapper } from './styled-alert';

const AlertMessage = ({message, type, margin, className, center, style, dataTestId = null }) => {
  return (
    <StyledAlertWrapper
      style={style}
      center={center}
      className={className}
      type={type}
      margin={margin}
      data-testid={dataTestId}
    >
      <span>{message}</span>
    </StyledAlertWrapper>
  )
}

export default AlertMessage