import React, { useState } from "react";
import { getSynchronizedTime } from "../../utils/serverTime";
import FooterInfoLinks from "./footer-info-links";
import FooterDocumentLinks from "./footer-document-links";
import FooterAddressLinks from "./footer-address-links";
import FooterSocialLinks from "./footer-social-links";
import FooterMerchants from "./footer-merchants";
import FooterDeveloped from "./footer.developed";
import { useTranslation } from "react-i18next";

import { StyledContainer } from "../styles/styled-container";
import { StyledFooter, StyledFooterContent, StyledMerchantsAndEdgeportBlock } from "./styled-footer";
import CookieMessage from "../cookie-message/cookie-message.component";
import Cookies from "js-cookie";
import EdgeportSign from "../../assets/images/edgeport_sign.svg"
import EdgeportSignDark from "../../assets/images/edgeport_sign_dark.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = ({ theme }) => {
  const initialCookies = {
    strictlyNecessary: true,
    functional: false,
    performance: false,
    targeting: false,
    agree: false
  };
  const [cookie, setCookie] = useState(initialCookies);

  const currentYear = (getSynchronizedTime().getFullYear());

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <StyledFooter>
      {!Cookies.get("useCookiesConsent") && <CookieMessage cookie={cookie} setCookie={setCookie} />}
      <StyledContainer wrapper="content">
        <StyledFooterContent>
          <FooterInfoLinks />
          <FooterDocumentLinks />
          <FooterAddressLinks />
          <FooterSocialLinks />
        </StyledFooterContent>
        <StyledMerchantsAndEdgeportBlock>
          <FooterMerchants theme={theme} />
          <a href="https://www.edgeport.com/" target="_blank">
            <LazyLoadImage
              src={theme === 'light' ? EdgeportSignDark : EdgeportSign}
              alt="Enhance Security and Performance of Websites and Applications: WAAP, WAF, DDoS Protection, Bot Management, Rate Limiting, ADN & CDN, SIEM"
            />
          </a>
        </StyledMerchantsAndEdgeportBlock>
        <div className="footer-author">
          {ready && `© Coin24.io, 2018—${currentYear}.  ${t("rights")}`}
        </div>
        <FooterDeveloped theme={theme} />
      </StyledContainer>
    </StyledFooter>
  );
};

export default Footer;