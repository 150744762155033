import styled from 'styled-components';

let name;

const handleChangeNameLocalStorageTable = (nameLocalStorage) => {
  name = nameLocalStorage
}

const removeColHeader = (id) => {

  if (!name || !localStorage.getItem(name)) {
    return;
  }

  const getShowHiddenColTable = Buffer.from(localStorage.getItem(name), 'base64').toString('utf-8');

  const decodedObject = JSON.parse(getShowHiddenColTable);

  if(decodedObject && !decodedObject[id]?.visible) {
    return `
      display: none;
    `
  }
}

const removeColRow = (id) => {
  if (!name || !localStorage.getItem(name)) {
    return;
  }
  const getShowHiddenColTable = Buffer.from(localStorage.getItem(name), 'base64').toString('utf-8');

  const decodedObject = JSON.parse(getShowHiddenColTable);

  if(decodedObject && decodedObject.hasOwnProperty(id) && !decodedObject[id]?.visible) {
    return `
      display: none !important;
    `
  }
}

export const StyledScrollTable = styled.div`
  overflow: auto;
  overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 9px;
    background-color: ${({theme}) => theme.bgElements};
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track{
    background: ${({theme}) => theme.hoverShadow};
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 15px;
    background-color: ${({theme}) => theme.defaultColor};
    border: 2px solid ${({theme}) => theme.hoverShadow};
    border-radius: 12px;
  }
`;

export const StyledTable = styled.div`
  .sticky {
    width: ${({widthScrollHeader}) => widthScrollHeader && `${widthScrollHeader}px`} !important;
    top: 55px !important;
    background-color: ${({theme}) => theme.bgElements};
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    z-index: 100;
    overflow-x: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-track{
    }
    &::-webkit-scrollbar-thumb {
    }
    @media screen and (max-width: 992px) {
      top: 0 !important;
    }
  }
  
  padding-bottom: 15px;
  ${({width}) => width && `min-width: ${width}px`}; 
  ${({width}) => width && 'width: 100%'}; 
  ${({width}) => width && 'overflow-x: hidden;'};

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  
  ${({nameLocalStorage}) => handleChangeNameLocalStorageTable(nameLocalStorage)}
`;

export const StyledTableHeader = styled.div`
  padding: 12px 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${({tableStickyCol, col}) => tableStickyCol ? tableStickyCol : `repeat(${col}, 150px)`};
  grid-gap: 15px;
  @media screen and (max-width: 992px) {
    display: ${({scroll}) => scroll === 'auto' ? 'grid' : 'none'};
  }
`;

export const StyledColHead = styled.div`
  font-weight: 700;
  ${({ hasSorting }) => hasSorting && `
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 6px;
    cursor: pointer;
    
    .icon-chevron-down, .icon-chevron-up {
      margin-top: 1px;
    }
  `};
  .sort-icon {
    font-size: 10px;
    opacity: 0.75;
    transform: rotate(90deg);
  }
  ${({id}) => removeColHeader(id)}
`;

export const StyledTableBody = styled.div``;

export const StyledRow = styled.div`
  padding: 12px 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${({ tableStickyCol, col }) => tableStickyCol ? tableStickyCol : `repeat(${col}, 150px)`};
  grid-column-gap: 15px;

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.bgElements};
  }

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  ${({ highlightedRow, theme }) => highlightedRow && `background-color: ${theme.selectedRow} !important}`};
  
  @media screen and (max-width: 992px) {
    grid-template-rows: ${({ scroll }) => scroll === 'auto' ? '1fr' : `repeat(${({ col }) => col}, minmax(50px, auto))`};
    grid-template-columns: ${({ scroll }) => scroll === 'auto' ? `repeat(${({ col }) => col}, 150px)` : '1fr'};
    grid-row-gap: 10px;
    ${({ scroll }) => scroll && `
       & > div {
        margin-bottom: inherit;
        padding-top: inherit;
         &:before {display: none}; 
        }
    `};
  }
`;

export const StyledCol = styled.div`
  align-items: center;
  position: relative;
  display: ${({ inline }) => inline ? "inline-flex" : "grid"};
  ${({id}) => removeColRow(id)}
  &:before {
    content: attr(data-title)':';
    font-size: 11px;
    letter-spacing: 0.4px;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }
  @media screen and (max-width: 992px) {
      margin-bottom: 5px;
      padding-top: 20px;
      align-items: start;
    }
`;