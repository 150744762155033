import React from "react";

import { StyledTitle, StyledTitleDescription, StyledTitleWrapper } from "./styled-title";

const Title = ({ as, className, title, description, style }) => {
  return (
    <StyledTitleWrapper className={className} style={style}>
      <StyledTitle as={as}>
        {title}
      </StyledTitle>
     {/* {description &&
      <StyledTitleDescription>
        {description}
      </StyledTitleDescription>}*/}
    </StyledTitleWrapper>
  );
};
export default Title;