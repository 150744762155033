import React from "react";
import NavInfoMenuItem from "./nav-information-menu-item";
import { useTranslation } from "react-i18next";

import { StyledInfoMenuList, StyledInfoMenuWrapper } from "./styled-dropdown-nav";

const AboutMenu = () => {
  const { t } = useTranslation("navigations");

  return (
    <StyledInfoMenuWrapper className="info-menu">
      <div className="info-menu__title">
        {t("information.subTitle1")}
      </div>
      <StyledInfoMenuList>
        <NavInfoMenuItem
          to="/about-us"
          icon="logo-coin24"
          linkTitle={t("information.aboutUs")}
        />
        <NavInfoMenuItem
          to="/news"
          icon="menu-news"
          linkTitle={t("information.news")}
          rel="canonical"
          /* TODO: uncomment after full integration of Wordpress news */
          // target="_blank"
        />
        <NavInfoMenuItem
          to="/cities"
          icon="menu-city"
          linkTitle={t("information.cities")}
          rel="canonical"
        />
        <NavInfoMenuItem
          to="/reviews"
          icon="menu-reviews"
          linkTitle={t("information.reviews")}
        />
        <NavInfoMenuItem
          to="/partners"
          icon="menu-partners"
          linkTitle={t("information.partners")}
        />
        <NavInfoMenuItem
          to="/network-confirmations"
          icon="menu-confirm"
          linkTitle={t("information.networkConfirmations")}
        />
      </StyledInfoMenuList>
    </StyledInfoMenuWrapper>
  );
};

export default AboutMenu;