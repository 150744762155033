import { useApolloClient } from "@apollo/react-hooks";
import axios from "axios";
import i18n from "i18next";
import React, { createContext, Suspense, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./assets/css/main.min.css";

import "./assets/fonts/OpenSans/style.css";
import "./assets/fonts/theme-icons/style.css";
import "./assets/images/css-sprite/icons.min.css";
import BackToTopButton from "./components/back-to-top/back-to-top.component";
import Footer from "./components/footer/footer.component";
import Header from "./components/header/header.component";

import { useDarkMode } from "./components/hooks/use-dark-mode.hooks";
import LoadRouteComponent from "./components/load-router/load-route.component";
import AutoLogoutMessage from "./components/modal/auto_logout_message";
import UserStatusMessage from "./components/modal/user-status-message.component";
import ScrollToTopProvider from "./components/scroll-top-top/scroll-top-top";
import PageSpinner from "./components/spinner/page-spinner.component";
import { GlobalStyle } from "./components/styles/global-style";
import { darkTheme, lightTheme } from "./components/styles/theme";
import TokenClickConversionNewFormat from "./components/traffic/traffic-click-conversion-new.component";
import { GET_USER_RBAC_DETAILS } from "./graphql/queries/user.query";

import "./i18n";
import routes from "./routes";

import guestRoutes from "./routes/guestRoutes";
import { authenticationConfig } from "./utils/authenticationConfig";
import { getExpirationDate, isExpired } from "./utils/checkExpiredToken";
import { defaultLanguage, responseStatus, supportedLanguages } from "./utils/consts.util";
import { checkAndSetSingle } from "./utils/cookies.utils";
import { getTimeOffset } from "./utils/serverTime";
import { PusherService } from "./utils/socketPusherService";
import Home from "./pages/home/home.component";

export const AuthContext = createContext({});

const App = () => {
  window.localStorage.removeItem("appLang");

  const [lngFromUrl, setLngFromUrl] = useState(defaultLanguage);

  const [geoData, setGeoData] = useState(null);

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const [authenticated, setAuthenticated] = useState(!!localStorage.getItem("token"));

  const client = useApolloClient();

  const { userId, userRole } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  const referrerCookieKey = "referrer";
  const referrer = window.document.referrer;

  let referrerLink = document.createElement('a');

  const [, setCookie] = useCookies([referrerCookieKey]);

  useEffect(() => {
    if (referrer && referrer.length) {
      referrerLink.href = referrer;

      let domain = referrerLink.protocol + '//' + referrerLink.hostname + '/';

      if (domain !== process.env.REACT_APP_DOMAIN_URL){
        checkAndSetSingle(referrerCookieKey, referrer, setCookie);
      }
    }
  }, []);

  let locale = "";

  Object.entries(supportedLanguages).map(([key, language]) => {
    locale = `${locale}${language.routePrefix}|`
  });

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLngFromUrl(language);
    });
    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      if (authenticated) {
        axios.get("/api/is-valid-token", authenticationConfig()).then();
      }
    });
    return () => {
      i18n.off("languageChanged");
    };
  }, [authenticated]);

  useEffect(() => {
    const fetchTimeOffset = async () => {
      await getTimeOffset();
    };

    fetchTimeOffset();
  }, []);

  const renderRoutesByAuth = () => {
    return authenticated ?
      routes.map((route, i) => (
        <LoadRouteComponent key={i} {...route} />
      )) :
      guestRoutes.map((route, i) => (
        <LoadRouteComponent key={i} {...route} />
      ));
  };

  const redirectToLogin = () => {
    if (!authenticated && window.location.pathname.includes("panel")) {
      sessionStorage.setItem("link", window.location.pathname);

      return <Redirect to="/login" />;
    }
  };

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  useEffect(() => {
    if (authenticated && !localStorage.getItem("country")) {
      axios.get("https://api.bigdatacloud.net/data/reverse-geocode-client?" +
        "latitude=XXXXXXXXXXXX&longitude=XXXXXXXXXXXX&localityLanguage=en").then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setGeoData(response.data);
          localStorage.setItem("country", response.data.countryCode);
        }
      });
    }

  }, [authenticated]);

  useEffect(() => {
    PusherService.initNewConnection();

    return () => {
      PusherService.unsubscribeFromAllChannels();
    };
  }, []);

  useEffect(() => {
    if (geoData) {
      axios.put("/api/users/" + userId, { lastLoginCountry: geoData.countryCode }, authenticationConfig()).then();
    }
  }, [geoData]);

  if (!mountedComponent) return <div />;

  return (
    <AuthContext.Provider value={{
      authenticated,
      setAuthenticated
    }}>
      <ThemeProvider theme={themeMode}>
        <TokenClickConversionNewFormat />
        <HelmetProvider>
          <Helmet>
            <html lang={lngFromUrl} />
          </Helmet>
          <Router>
            <ScrollToTopProvider>
              <React.Suspense fallback={<>...</>}>
                <UserStatusMessage
                  setAuthenticated={setAuthenticated}
                />
              </React.Suspense>
              <Suspense fallback={<PageSpinner/>}>
                <AutoLogoutMessage />
                <Header
                  theme={theme}
                  toggleTheme={themeToggler}
                />
                <Switch>
                  {renderRoutesByAuth()}
                  {redirectToLogin()}
                  <Route
                    exact={true}
                    path={`/:locale(${locale})?/:id(.refToken=[a-z-_0-9]*&cur_from=[A-Za-z0-9]*&cur_to=[A-Za-z0-9]*|[a-z-0-9@&]*-to-[a-z-0-9@&]*)?`}
                    component={Home}
                  />
                  <Redirect from="*" to="/404" />
                </Switch>
                <Footer theme={theme} />
                <BackToTopButton />
              </Suspense>
            </ScrollToTopProvider>
          </Router>
          <GlobalStyle />
        </HelmetProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
