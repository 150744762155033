import { useQuery } from "@apollo/react-hooks";
import React, { useEffect } from "react";
import { GET_CLIENT_ACCOUNT_DETAILS } from "../../graphql/queries/account.query";
import { StyledDocumentVerification } from "../sidebar/styled-sidebar";

const BadgeNotificationClientDocumentVerified = ({ t, userId, visible }) => {
  const { data, loading, error, refetch } = useQuery(GET_CLIENT_ACCOUNT_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      id: `/api/clients/${userId}`
    }
  });

  useEffect(() => {
    if (!visible || loading) {
      return;
    }

    refetch();
  }, [visible]);

  if (loading || !data) {
    return null;
  }

  if (data?.accountDetailsClient?.isVerified) {
    return null;
  }

  return (
    <StyledDocumentVerification data-testid="sidebar-notification-documents" title={t("documentVerificationFalse")}>
      <span className="icon-exclamation" />
    </StyledDocumentVerification>
  );
};
export default BadgeNotificationClientDocumentVerified;
