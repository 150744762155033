import React from "react";
import Moment from "react-moment";
import { DateTime } from "luxon";

const format = "DD.MM.YYYY HH:mm:ss";
const format2 = "DD.MM.YYYY";

const TimestampToDate = (str) => {
  return <Moment format={format}>{new Date(str * 1000)}</Moment>;
};

const TimestampToDateWithoutTime = (str) => {
  return <Moment format={format2}>{new Date(str * 1000)}</Moment>;
};

const DateToFormat = (str) => {
  return <Moment format={format}>{str}</Moment>;
};

const TimestampToDateWithTimezone = (str) => {
  return DateTime.fromMillis(str * 1000).toFormat("dd.MM.yyyy HH:mm:ss ZZZZ");
};

const TimestampToDateUTC = (str) => {
  const dateTime = DateTime.fromSeconds(str, { zone: "utc" });
  const formattedDate = dateTime.toFormat("dd.MM.yyyy HH:mm");

  return formattedDate + " UTC+0";
};

const TimestampToDateWithDay = (str) => {
  return DateTime.fromMillis(str * 1000).toFormat("dd.MM.yyyy");
};

const TimestampToDateWithHours = (str) => {
  return DateTime.fromMillis(str * 1000).toFormat("HH:mm");
};

export {
  TimestampToDate,
  DateToFormat,
  TimestampToDateWithoutTime,
  TimestampToDateWithTimezone,
  TimestampToDateUTC,
  TimestampToDateWithDay,
  TimestampToDateWithHours
};
