import React from "react";
import Dropdown from "rc-dropdown";
import Toggle from "../theme-toggler/theme-toggler.component";
import NavItem from "./nav-item.component";
import Sidebar from "../sidebar/sidebar.component";
import InformationMenu from "./dropdown-items/nav-information-menu";
import LanguageSelect from "../header/language/language-select";
import { useTranslation } from "react-i18next";

import { StyledDropdownMenuItem, StyledMenuList, StyledNavigation } from "./styled-navigation";
import { StyledButtonOn } from "../header/decorations/styled-christmas-garland";

const Nav = ({ theme, toggleTheme, isLoggedIn, light, setLight }) => {
  const { t, ready } = useTranslation(["navigations", "sideBar"], { useSuspense: false });

  if (!ready) {
    return null;
  }

  return (
    <StyledNavigation id="nav">
      {/*
        To control the states of the garland, add the
         <StyledButtonOn/>
        (Uncomment the code below)
      */}
      {/*<StyledButtonOn
        title={light === true ? t("light.turnOff") : t("light.turnOn")}
        light={light}
        onClick={() => {setLight(!light);}}
      />*/}
      <Toggle
        theme={theme}
        toggleTheme={toggleTheme}
      />
      <LanguageSelect />
      {ready &&
        <React.Fragment>
          <StyledMenuList>
            <NavItem
              to="/"
              exact
              icon="exchange"
              linkTitle={t("exchange")}
            />
            <NavItem
              to="/rates"
              icon="rates"
              linkTitle={t("rates")}
              dataTestId="rates"
            />
            <Dropdown
              getPopupContainer={() => document.getElementById("nav")}
              overlay={InformationMenu}
              trigger={["click"]}
              placement="bottomCenter"
              animation="slide-up"
            >
              <StyledDropdownMenuItem
                data-testid="information-desktop"
                className="menu-item"
                onClick={(event) => event.preventDefault()}
              >
                {t("information.title")} <span className="icon-chevron-down button-down" />
              </StyledDropdownMenuItem>
            </Dropdown>
            {/* mobile menu item start*/}
            <NavItem
              to="/info"
              icon="info"
              linkTitle={t("information.title")}
              className="information-link"
              dataTestId="information-mobile"
            />
            {/* mobile menu item end*/}
            <NavItem
              to="/contacts"
              icon="contacts"
              linkTitle={t("contacts")}
              dataTestId="contacts"
            />
            {!isLoggedIn ? <NavItem
                to="/login"
                icon="user"
                linkTitle={t("login")}
                className="login-link"
                dataTestId="login-link"
              /> :
              <NavItem
                to="/panel/account"
                icon="user"
                linkTitle={t("account")}
                className="account-link"
                dataTestId="account-link"
              />}
          </StyledMenuList>
          {isLoggedIn && <Sidebar />}
        </React.Fragment>
      }
    </StyledNavigation>
  );
};

export default Nav;