import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import Can from "../../components/can/can.component";
import { requisition } from "../../rbac-consts";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import OldRequisitionContainer from "../../components/old-requisition-list/old-requisition.container";
import { Redirect } from "react-router-dom";

const OldRequisitionPage = () => {

  const client = useApolloClient();

  const { userRole, createdAt } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  return (
    <Can
      role={userRole}
      perform={requisition.READ}
      yes={() => (
        parseInt(process.env.REACT_APP_DATE_OF_LAST_REQUISITION) >= createdAt ?
          <OldRequisitionContainer /> : <Redirect to="/404" />)}
      no={() => <Redirect to="/404" />}
    />
  );
};

export default OldRequisitionPage;