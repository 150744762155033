import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { useTranslation } from "react-i18next";

import { GET_CITY_BY_EXTERNAL_ID_AND_LANGUAGE } from "../../graphql/queries/cities.query";
import { arrayElements } from "../../utils/consts.util";
import { getCookie } from "../../utils/cookies.utils";
import AlertMessage from "../alert/alert.component";

const RequisitionCity = ({ exchangePoint }) => {
  const { data, loading, error } = useQuery(GET_CITY_BY_EXTERNAL_ID_AND_LANGUAGE, {
    variables: {
      externalId: parseInt(exchangePoint),
      language: getCookie("i18next")
    },
    fetchPolicy: "network-only"
  });

  const { t } = useTranslation("requisition");

  if (loading) {
    return t("city.load");
  }

  if (error) {
    return <AlertMessage type="info" message="Error" />;
  }

  const { getCityTranslateNameCityTranslations } = data;

  if (!getCityTranslateNameCityTranslations?.length) {
    return <AlertMessage type="warning" message={t("city.notCity")} />;
  }

  return (
    <>{getCityTranslateNameCityTranslations[arrayElements.ZERO].value}</>
  );
};

export default RequisitionCity;