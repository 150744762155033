import React from "react";
import { useTranslation } from "react-i18next";

import { StyledFooterBlock, StyledFooterContentItem } from "./styled-footer";

const FooterSocialLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });
  
  return (
    <StyledFooterBlock className="footer-social-links">
      <StyledFooterContentItem className="footer-item footer-social-links__email">
        <div className="footer-item__title">
          {ready && t("linkEmailTitle")}
        </div>
        {
          ready &&
          <ul className="footer-item__list">
           <li>
              <a href="mailto:info@coin24.io">
                info@coin24.io
              </a>
            </li>
          </ul>
        }
      </StyledFooterContentItem>
      <StyledFooterContentItem className="footer-item footer-social-links__social-list">
        <div className="footer-item__title">
          {ready && t("linkSocTitle")}
        </div>
        {
        ready &&
        <ul className="footer-item__list social-wrapper">
          <li>
            <a
              href="tg://resolve?domain=coin24io"
              target="_blank"
              rel="noreferrer"
              aria-label="telegram"
            >
              <span className="social-icon-telegram"/>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/coin24io"
              target="_blank"
              rel="noreferrer"
              aria-label="facebook"
            >
              <span className="social-icon-facebook"/>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/coin24.io/"
              target="_blank"
              rel="noreferrer"
              aria-label="instagram"
            >
              <span className="social-icon-instagram"/>
            </a>
          </li>
          <li>
            <a
              href="https://www.threads.net/@coin24.io"
              target="_blank"
              rel="noreferrer"
              aria-label="threads"
            >
              <span className="social-icon-threads"/>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/Coin24io"
              target="_blank"
              rel="noreferrer"
              aria-label="twitter"
            >
              <span className="social-icon-twitter"/>
            </a>
          </li>
        </ul>
        }
      </StyledFooterContentItem>
    </StyledFooterBlock>
  );
};

export default FooterSocialLinks;