import React from "react";
import CustomLink from "../customLink/CustomLink";
import { useTranslation } from "react-i18next";

import Coin24Logo from "../../assets/images/logo.svg";

import {
  SiteDescription,
  SiteName,
  SiteTitle,
  StyledLogo,
  StyledLogoIcon,
  StyledLogoIconWrapper,
  StyledLogoShadow
} from "./styled-logo";


const Logo = ({ fixed }) => {
  const { t, ready } = useTranslation("navigations", { useSuspense: false })

  return (
    <CustomLink to="/" className="logo">
      {/*
        To add a Christmas logo, add the "christmas" attribute.
        For example
        <StyledLogo christmas>..
      */}
      <StyledLogo fixed={fixed}>
        <StyledLogoShadow className="logo-shadow">
          <StyledLogoIconWrapper>
            <StyledLogoIcon className="logo-icon">
              <img alt="coin24-logo" src={Coin24Logo} width="50" height="55" />
            </StyledLogoIcon>
          </StyledLogoIconWrapper>
        </StyledLogoShadow>
        <SiteTitle className="site-title">
          <SiteName>
            Coin24
          </SiteName>
          <SiteDescription>
            {ready && t("logo.subText")}
          </SiteDescription>
        </SiteTitle>
      </StyledLogo>
    </CustomLink>
  );
};

export default Logo;