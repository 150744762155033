import styled from "styled-components";
import { StyledReviewsPostContent } from "../../review/styled-reviews";

export const StyledReviewsSection = styled.section`
  padding: 50px 0;

  .home-reviews-section {
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__footer {
      padding-top: 40px;
      text-align: center;
    }

    &__title {
      margin-bottom: 25px;
    }

    &__content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 350px));
      grid-gap: 30px;

      @media screen and (max-width: 992px) {
        grid-template-columns: 100%;
        grid-gap: 15px;
      }
    }
    
    &__item {
      ${StyledReviewsPostContent} {
        opacity: 0.9;
      }
    }
  }
`;