export const lightTheme = {
    body: '#fff',
    bgElements: '#F8F8F8',
    borderElements: '#CDCDCD',
    lightBg: '#FCFCFC',
    text: '#202020',
    textOpacity: 'rgba(32, 32, 32, 0.40)',
    textReadonly: 'rgba(32, 32, 32, 0.7)',
    textReadonlyHex: '#2020201A',
    defaultColor: '#EC6110',
    switchPosition: '1px',
    navBarBg: '#F5F5F5',
    navBarBorder: '#CDCDCD',
    border: "#EFEFEF",
    activeInputBorder: '#202020',
    requisitionStatusDefault: '#e9e9e9',
    hoverColor: '#FEF0E8',
    hoverShadow: '#F9E2D4',
    skeletonBg: 'rgba(0, 0, 0, 0.11)',
    skeletonBgGradient: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent)',
    loadingForm: 'rgba(250, 250, 255, 0.5)',
    errorRow: '#fbc8c8',
    disabledText: "rgba(32,32,32,0.75)",
    tabs: {
        leftMobileGradient: "linear-gradient(to left, #f28c3c1f, #f28c3c63)",
        rightMobileGradient: "linear-gradient(to right, #f28c3c1f, #f28c3c63)"
    },
    selectedRow: 'rgba(24, 133, 242, 0.2)',
    modalLoaderBg: 'rgba(255, 255, 255, 0.55)'
}

export const darkTheme = {
    body: '#202020',
    bgElements: '#181818',
    borderElements: '#131313',
    lightBg: '#1b1b1b',
    text: '#fff',
    textOpacity: '#fff',
    textReadonly: 'rgba(255, 255, 255, 0.7)',
    textReadonlyHex: '#FFFFFF1A',
    defaultColor: '#EC6110',
    switchPosition: 'calc(100% - 19px)',
    navBarBg: '#141414',
    navBarBorder: '#181818',
    border: "#303030",
    activeInputBorder: '#fff',
    requisitionStatusDefault: '#131313',
    hoverColor: '#35271F',
    hoverShadow: '#33241B',
    skeletonBg: 'rgba(255, 255, 255, 0.11)',
    skeletonBgGradient: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent)',
    loadingForm: 'rgba(10, 10, 10, 0.5)',
    errorRow: '#5c1717',
    disabledText: "rgba(255,255,255,0.75)",
    tabs: {
        leftMobileGradient: "linear-gradient(to left, #102f332e, #b2c0c387)",
        rightMobileGradient: "linear-gradient(to right, #102f332e, #b2c0c387)"
    },
    selectedRow: 'rgba(24, 133, 242, 0.2)',
    modalLoaderBg: 'rgba(32, 32, 32, 0.55)'
}