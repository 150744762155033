import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTopProvider ({ history, children }) {
  const refHistoryLocation = useRef(history.location);

  useEffect(() => {
    const unlisten = history.listen((e) => {
      if (e.state) {
        return;
      }

      const currentPageRef = parseInt(new URLSearchParams(refHistoryLocation.current.search).get("page") ?? 1);
      const currentPageHistory = parseInt(new URLSearchParams(history.location.search).get("page") ?? 1);

      const pageChanged = currentPageRef !== currentPageHistory;

      if (refHistoryLocation.current.pathname !== history.location.pathname || pageChanged) {
        window.scrollTo(0, 0);
      }

      refHistoryLocation.current = history.location;
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
}

export default withRouter(ScrollToTopProvider);