import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { GET_NEW_REVIEW } from "../../graphql/queries/review.query";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";

import { StyledBadgeNotification } from "./styled-badge-notification";

const BadgeNotificationReview = () => {
  const [count, setCount] = useState("");

  const { refetch } = useQuery(GET_NEW_REVIEW, {
    onCompleted: (data) => {
      if (data) {
        const { reviews } = data;
        const { totalCount } = reviews.paginationInfo;
        setCount(totalCount);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    let channel = pusherChannels.REVIEWS.REVIEWS_BADGE;

    if (!refetch) {
      return;
    }

    PusherService.connectToSocket(
      channel,
      pusherEvents.REVIEWS.CREATE,
      () => {refetch();}
    );

    PusherService.connectToSocket(
      channel,
      pusherEvents.REVIEWS.UPDATE,
      () => {refetch();}
    );

    PusherService.connectToSocket(
      channel,
      pusherEvents.REVIEWS.DELETE,
      () => {refetch();}
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, [refetch]);

  if (count <= 0) return null

  return (
    <StyledBadgeNotification data-testid="sidebar-notification-reviews">
      {count}
    </StyledBadgeNotification>
  );
};
export default BadgeNotificationReview;
