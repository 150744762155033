/*
Функція changeStatusUnderStandard перетворює статуси які приходять
з бекенду під загально прийнятий стандарт, нижче приведені приклади

      1) Якщо прийшли статуси формату - "inProgress" або "new" і треба
      на клієнта вивести в форматі - "In progress" та "New"

      const result = string.replace(regex.ALPHABET, " $1").toLowerCase();
      return result.charAt(0).toUpperCase() + result.slice(1);

      2) Якщо прийшли статуси формату - "IN_PROGRESS" або "PENDING" і треба
      на клієнта вивести в форматі - "In progress" та "Pending"

      const result = "IN_PROGRESS".toLowerCase().replace(regex.REGEX_STANDARD_STATUS, " ");
      return result.charAt(0).toUpperCase() + result.slice(1);
*/


import { invoiceTags, regex } from "./consts.util";

const openPdfInNewTab = (base64String, type) => {
  const decodedPdf = Buffer.from(base64String.split(`data:image/${type};base64,`)[1], "base64").toString("utf-8");

  const byteArray = new Uint8Array(decodedPdf.length);

  for (let i = 0; i < decodedPdf.length; i++) {
    byteArray[i] = decodedPdf.charCodeAt(i);
  }

  const pdfFile = new Blob([byteArray], { type: `${type === "pdf" ? "application" : "image"}/${type}` });

  return URL.createObjectURL(pdfFile);
};

const changeStatusUnderStandard = (string) => {
  if (string) {
    const result = string.replace(regex.ALPHABET, " $1").toLowerCase();
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
};

const intersectionObservable = (element) => {
  const intersection = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        entry.target.scrollIntoView({ behavior: "smooth", block: "center" });
        observer.unobserve(entry.target);
      }
    });
  });

  intersection.observe(element);
};

const findAndScrollToTheErrorFirstElement = (selectorElement) => {
  const element = document.querySelector(selectorElement);

  if (element) {
    intersectionObservable(element);
  }
};

const removeTrailingSlash = (url) => {
  if (url.length > 1 && url.endsWith("/")) {
    return url.slice(0, -1);
  }
  return url;
};

const removeLastNumbers = (tag, num) => {

  if (Number.isInteger(num)) {
    return num;
  }

  const numStr = num.toString();
  const decimalPart = numStr.split('.')[1];

  if (decimalPart && decimalPart.length <= 2) {
    return num;
  }

  if (tag === invoiceTags.CRYPTO) {
    return parseFloat(num).toFixed(6).replace(/\.?0+$/, "");
  } else {
    return parseFloat(num).toFixed(2);
  }
};

export {
  openPdfInNewTab,
  changeStatusUnderStandard,
  findAndScrollToTheErrorFirstElement,
  removeTrailingSlash,
  removeLastNumbers,
};