import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import HomeLoader from "./home.loader";
import { getCookie } from "../../utils/cookies.utils";
import { helmetHtmlByLang } from "../../utils/consts.util";

const TopPageSectionComponent = lazy(() => import("../../components/home/top-page/top-home-page-section"));
// const CalculatorContainer = lazy(() => import("../../components/calculator/calculator.container"));
const CalculatorContainer = lazy(() => import("../../components/new-calculator/CalculatorContainer"));
const AttentionSectionComponent = lazy(() => import("../../components/home/attention-section/attention-section"));
const LastExchangeSectionComponent = lazy(() => import("../../components/home/exchange-section/last-exchange-section"));
const RateChartContainer = lazy(() => import("../../components/rate-chart/rate-chart.container"));
const BountySectionComponent = lazy(() => import("../../components/home/bounty-section/bounty-section.component"));
const WhoAreWeSectionComponent = lazy(() => import("../../components/home/who-are-we-section/who-are-we-section.component"));
const NumCounterSectionComponent = lazy(() => import("../../components/home/num-counter-section/num-counter-section"));
const AboutSectionComponent = lazy(() => import("../../components/home/about-section/about-section.component"));
const ProfitSectionComponent = lazy(() => import("../../components/home/profit-section/profit-section"));
const ReviewsSectionComponent = lazy(() => import("../../components/home/reviews-section/reviews-section.component"));
const PopularExchangeSectionComponent = lazy(() => import("../../components/home/popular-exchanges-section/popular-exchange-section"));
const NewsSectionComponent = lazy(() => import("../../components/home/news-section/news-section.component"));
const UsefulSectionComponent = lazy(() => import("../../components/home/useful-section/useful-section.component"));
const MonitoringSectionComponent = lazy(() => import("../../components/home/monitoring-section/monitoring-section.component"));

const Home = () => {

  const { t } = useTranslation(["homePage", "flowAttributes", "auth", "errors"]);
  const currentLocale = getCookie("i18next");

  // if (!ready) {
  //   return <HomeLoader/>
  // }

  return (
    // <Suspense fallback={<HomeLoader />}>
    <>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta
          name="description"
          content={t("meta.description")}
        />
        <meta
          content={t("meta.title")}
          property="og:title"
        />
        <meta
          content={t("meta.description")}
          property="og:description"
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <link
          rel="canonical"
          href={`https://${window.location.hostname}/${currentLocale === "ru" ? "" : currentLocale}`}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/"}
          hrefLang={helmetHtmlByLang.ru}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ua"}
          hrefLang={helmetHtmlByLang.ua}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/en"}
          hrefLang={helmetHtmlByLang.en}
        />
        <meta name="keywords" content={t("meta.keywords")}/>
      </Helmet>
      <TopPageSectionComponent t={t}/>
      <CalculatorContainer t={t}/>
      <AttentionSectionComponent t={t} />
      {/*<LastExchangeSectionComponent />*/}
      {/*<PopularExchangeSectionComponent />*/}
      {/*<RateChartContainer t={t} />*/}
      <BountySectionComponent t={t} />
      <WhoAreWeSectionComponent t={t} />
      <NumCounterSectionComponent t={t} />
      <AboutSectionComponent />
      <ProfitSectionComponent t={t} />
      <ReviewsSectionComponent />
      <NewsSectionComponent />
      <UsefulSectionComponent t={t} />
      <MonitoringSectionComponent t={t} />
    </>
    // </Suspense>
  );
};

export default Home;
