import React, { useContext, useState } from "react";
import Dialog from "rc-dialog";
import { useTranslation } from "react-i18next";
import "rc-dialog/assets/index.css";
import { PusherService } from "../../utils/socketPusherService";
import { StyledButton } from "../styles/styled-button";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { CHANGE_ONLINE } from "../../graphql/mutations/user.mutation";
import { useIdleTimer } from "react-idle-timer";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import {useHistory} from "react-router-dom";
import { AuthContext } from "../../App";

const AutoLogoutMessage = () => {
  const history = useHistory();

  const {setAuthenticated} = useContext(AuthContext);

  const {t} = useTranslation("autoLogout");

  const client = useApolloClient();

  let role = "manager";

  const [setIsOnline] = useMutation(CHANGE_ONLINE);
  const [visible, setVisible] = useState(false);

  const handleOnIdle = event => {
    const { userRole, userId } = client.readQuery({
      query: GET_USER_RBAC_DETAILS,
    });

    if (userId) {
      if (userRole === role) {
        setIsOnline({ variables: { id: "/api/managers/" + userId, isOnline: false } });
      }

      localStorage.removeItem("token");
      localStorage.removeItem("country");

      PusherService.unsubscribeFromAllChannels();

      setVisible(true);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const modalContent = () => {
    const yes = () => {
      setVisible(false);
      setAuthenticated(false);

      client.writeData({
        data: { isLoggedIn: false, userId: "", userRole: "anonymous", username: "" }
      });

      if (!window.location.href.includes("/panel")) {
        return;
      }

      history.push("/login");
    };

    const style = {
      textAlign: 'justify'
    }

    return (
      <>
        <div className="default-modal__body-content">
          <p style={style}>
            {t("p1")}
          </p>
          <p style={style}>
            {t("p2")}
          </p>
        </div>
        <div className="default-modal__body-footer">
          <StyledButton color="main" onClick={yes} weight="normal">
            Ok
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <Dialog
      visible={visible}
      wrapClassName="rc-modal-center"
      closeIcon={false}
      closable={false}
      animation="zoom"
      maskAnimation="fade"
      title={t("title")}
      className="default-modal"
    >
      {modalContent()}
    </Dialog>
  );
};

export default AutoLogoutMessage;