import React from "react";
import { useTranslation } from "react-i18next";

import { StyledFooterBlock, StyledFooterContentItem } from "./styled-footer";

const FooterAddressLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });
  
  return (
    <StyledFooterBlock className="footer-address-links">
      {/* <StyledFooterContentItem className="footer-item">
        <div className="footer-item__title">
          {ready && t("linkAddrTitle")}
        </div>
        <ul className="footer-item__list">
          <li>
            {ready && t("linkAddrStreet")}
          </li>
          <li>
            {ready && t("linkAddrCity")}
          </li>
          <li>
            {ready && t("linkAddrReg")}
          </li>
        </ul>
      </StyledFooterContentItem> */}
      <StyledFooterContentItem className="footer-item">
        <div className="footer-item__title">
          {ready && t("linkTimeTitle")}
        </div>
        <ul className="footer-item__list">
          <li>
            {ready && t("linkTimeWork")}
          </li>
          <li>
            {ready && t("linkTimeTP")}
          </li>
        </ul>
      </StyledFooterContentItem>
    </StyledFooterBlock>
  );
};

export default FooterAddressLinks;