import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { GET_NOT_VERIFIED_CREDIT_CARD } from "../../graphql/queries/credit-card.query";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";

import { StyledBadgeNotification } from "./styled-badge-notification";

const BadgeNotificationCreditCard = () => {
  const [count, setCount] = useState("");

  const { refetch } = useQuery(GET_NOT_VERIFIED_CREDIT_CARD, {
    onCompleted: (data) => {
      if (data) {
        const { creditCards } = data;
        const { totalCount } = creditCards.paginationInfo;
        setCount(totalCount);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    let channel = pusherChannels.CREDIT_CARD.CREDIT_CARD_BADGE;

    if (!refetch) {
      return;
    }

    PusherService.connectToSocket(
      channel,
      pusherEvents.CREDIT_CARD.UPDATE,
      () => {refetch();}
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, [refetch]);

  if (count <= 0) return null

  return (
    <StyledBadgeNotification data-testid="sidebar-notification-credit-card">
      {count}
    </StyledBadgeNotification>
  );
};
export default BadgeNotificationCreditCard;
