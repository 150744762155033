import React from "react";
import CustomLink from "../../customLink/CustomLink";
import { useTranslation } from "react-i18next";

import { StyledInfoMenuList, StyledInfoMenuWrapper } from "./styled-dropdown-nav";


const CryptoMenu = () => {
  const { t } = useTranslation("navigations");

  return (
    <StyledInfoMenuWrapper className="crypto-menu">
      <div className="info-menu__title">
        {t("information.subTitle2")}
      </div>
      <StyledInfoMenuList className="crypto-list">
        <li className="info-menu-item">
          <CustomLink
            to="/crypto-dictionary"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.cryptoDictionary")}
            </div>
          </CustomLink>
        </li>
        <li className="info-menu-item">
          <CustomLink
            to="/all-exchange-pairs"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.exchangePairs")}
            </div>
          </CustomLink>
        </li>
        <li className="info-menu-item">
          <CustomLink
            to="/bitcoin-price"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.kursBitcoina")}
            </div>
          </CustomLink>
        </li>
        <li className="info-menu-item">
          <CustomLink
            to="/cryptocurrency-exchange"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.kupitKriptovalyutu")}
            </div>
          </CustomLink>
        </li>
        <li className="info-menu-item">
          <CustomLink
            to="/buy-cryptocurrency"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.obmenKriptovalut")}
            </div>
          </CustomLink>
        </li>
        <li className="info-menu-item">
          <CustomLink
            to="/bitcoin-wallet"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.bitcoinKoshelek")}
            </div>
          </CustomLink>
        </li>
        <li className="info-menu-item">
          <CustomLink
            to="/exchange-usdt"
            className="info-menu-link"
            activeClassName="info-menu-link_current"
          >
            <div className="info-menu-link__title crypto-list__title">
              {t("information.exchangeUSDT")}
            </div>
          </CustomLink>
        </li>
      </StyledInfoMenuList>
    </StyledInfoMenuWrapper>
  );
};

export default CryptoMenu;